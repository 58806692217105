"use client";

import Script from "next/script";

export const UmamiHead = ({
  umamiWebsiteId,
}: {
  umamiWebsiteId: string;
}): JSX.Element => (
  <Script
    async
    src="https://analytics.sync.com.br/script.js"
    data-website-id={umamiWebsiteId}
  />
);

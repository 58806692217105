"use client";

import { PlatformsThemeProvider } from "@sync/platforms/src/components/layout/PlatformsThemeProvider";
import { ThemeProvider } from "next-themes";
import cookies from "js-cookie";

export function Providers({ children, props }: { children: any; props: any }) {
  const mode = cookies.get("theme") ?? "light";

  return (
    <ThemeProvider
      enableSystem={true}
      attribute="class"
      defaultTheme={mode}
      themes={["light", "dark"]}
    >
      <PlatformsThemeProvider settings={props}>
        {children}
      </PlatformsThemeProvider>
    </ThemeProvider>
  );
}
